<template>
    <div class="main2">
        <div class="nav-box">
            {{title}}
        </div>
        <div class="top-info">
            <div v-for="(item, index) in splitTitleList" :key="index">
                <div v-if="index == 0" class="title1">{{ item }}</div>
                <div v-if="index == 1" class="title2">{{ item }}</div>
            </div>
        </div>
        <div class="line11"></div>
        <div class="video-box">
            <video id="myVideo" controls autoplay muted>
                <source :src="videoUrl" type="video/mp4" />
            </video>
            <img src="https://sho-static.shulan.com//minipro/202404081340100.jpg" class="logo"></img>
            <img v-if="presenterIntroductionImg" :style="labelShow ? 'opacity: 1' : 'opacity: 0'" :src="presenterIntroductionImg" class="label"></img>
        </div>
        <div class="line11"></div>
        <div class="bottom-box">
            <!-- <div class="title-box">
                    <div class="">{{ videoDetail.presenter }}</div>
                    <div class="title">{{ videoDetail.title }}</div>
                </div> -->
            <div class="bottom-tip">
                <img src="./info.png" style="width: 22px" alt="" />
                <!-- <icon type="info" size="16" color="#fff" /> -->
                <span style="margin-left: 10px">科普视频仅供参考</span>
            </div>
            <div style="height: 28px"></div>
        </div>
    </div>
</template>
<script>
import { Field, Button, Toast } from 'vant';
import Vue from 'vue';
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
    components: {},
    data() {
        return {
            labelShow: false,
            title: '',
            splitTitleList: [''],
            presenterIntroductionImg: '',
            videoUrl: ''
        };
    },

     created() {
        console.log('route data', this.$route.query);
        this.videoUrl = decodeURIComponent(this.$route.query.videoUrl);
        this.presenterIntroductionImg = decodeURIComponent(this.$route.query.presenterIntroductionImg);
        debugger
        if(this.presenterIntroductionImg.includes('?')) {
            const list = this.presenterIntroductionImg.split('?')
            this.presenterIntroductionImg = list[0] + '?' + encodeURIComponent(list[1])
        }
        this.title = this.$route.query.title || '';
        this.splitTitleList = this.title.split('-');
       
    },
    mounted() {
        var vid = document.getElementById('myVideo');
     
        vid.ontimeupdate = () => {
            var currentTime = vid.currentTime;
            if (currentTime > 10) {
                this.labelShow = false;
            } else {
                this.labelShow = true;
            }
        };
    },
    beforeDestroy() {},
    methods: {
        ondurationchange(e) {
            console.log(e);
        }
    }
};
</script>

<style lang="scss">
@function fit($num) {
    @return $num / 3;
}
#app {
    height: 100%;
}
.main2 {
    // background: rgb(24, 24, 24);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.nav-box {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #333333;
    font-size: 32px;
}
.top-info {
    background: #303047;
    height: 300px;
    // padding: 40px 40px 30px;
    line-height: 70px;
    // font-family: PingFang SC, PingFang SC;
    font-family: Source Han Sans, Source Han Sans;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title1 {
        margin-left: 40px;
        color: #35feeb;
        font-size: 60px;
        font-weight: bolder;
    }
    .title2 {
        margin-left: 40px;
        margin-top: 20px;
        color: #fff;
        font-size: 60px;
        font-weight: 900;
    }
}
.line11 {
    background: #30e5d4;
    height: 9px;
    width: 100%;
}
.video-box {
    width: 100%;
    height: 898px;
    position: relative;
    video {
        width: 100%;
        height: 100%;
    }
    .logo {
        position: absolute;
        top: 24px;
        left: 44px;
        width: 164px;
        height: 64px;
    }
    .label {
        width: 102px;
        height: 305px;
        position: absolute;
        top: 143px;
        left: 95px;
        opacity: 0;
        transition: opacity 1s ease;
    }
}
.bottom-box {
    flex: 1;
    background: #303047;
    display: flex;
    flex-direction: column;
    padding: 28px 0 28px 38px;
    justify-content: space-between;

    justify-content: center;
    .bottom-tip {
        display: flex;
        align-items: center;
        // position: absolute;
        // left: 24px;
        // bottom: 20px;
        font-family: PingFang SC Regular, PingFang SC Regular;
        font-weight: 400;
        font-size: 32px;
        color: #cacaca;
    }
    .title-box {
        // position: absolute;
        // left: 24px;
        // bottom: 110px;
        font-size: 32px;
        font-weight: 500;
        color: #fff;
        .title {
            margin-top: 10px;
            font-size: 28px;
        }
    }
}
</style>
